import { HiSignal } from "react-icons/hi2";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoTelegram } from "react-icons/bi";
import { RiWhatsappFill } from "react-icons/ri";
import Clock from "../asserts/pngwing.com.png";
import Star from "../asserts/pngwing.com (1).png";
import AllSport from "../asserts/pngwing.com (2).png";
import E_sport from "../asserts/pngwing.com (3).png";
import Tennis from "../asserts/pngwing.com (5).png";
import Soccer from "../asserts/pngwing.com (6).png";
import Ice_Hockey from "../asserts/pngwing.com (7).png";
import Disk from "../asserts/disk_k70itz0opoj0_64.png";
import Badminton from "../asserts/pngwing.com (8).png";
import TableTannis from "../asserts/pngwing.com (9).png";
import Bedminton from "../asserts/pngwing.com (10).png";
import HorseRacing from "../asserts/horse_ud28znzm5ysd_64.png";
import GrayHound from "../asserts/pharaoh_hound_unaekr57xuhy_64.png";
import Kabaddi from "../asserts/fighting_dzs3gt8asc6s_64.png";
// import Exclusive from '../asserts/pngwing.com (15).png'

export const eventItems = [
  {
    id: 1,
    icon: <HiSignal />,
    title: "Live Event",
    link: "/live_casino",
    count: 54,
  },
  {
    id: 2,
    icon: (
      <img
        src={Clock}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon
    title: "Upcoming ",
    link: "/upcomming",
    count: 23,
  },
  {
    id: 3,
    icon: (
      <img
        src={Star}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Favourite",
    link: "/favourite",
    count: 12,
  },
];
export const eventSidebarItems = [
  {
    id: 1,
    icon: (
      <img
        src={AllSport}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "All Sports",
    link: "/",
    count: "",
    countbg: "#202020",
  },
  {
    id: 2,
    icon: (
      <img
        src={E_sport}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    title: "E-Sports",
    link: "/e-sport",
    count: "",
    countbg: "#202020",
  },
  {
    id: 3,
    icon: (
      <img
        src="https://res.cloudinary.com/dionqkh8u/image/upload/v1726778156/3115782_drd6qf.png"
        alt="ball"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Cricket",
    link: "/cricket",
    count: 31,
    countbg: "#202020",
  },
  {
    id: 4,
    icon: (
      <img
        src={Tennis}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Tennis",
    link: "/tennis",
    count: 46,
    countbg: "#202020",
  },
  {
    id: 5,
    icon: (
      <img
        src={Soccer}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Soccer",
    link: "/soccer",
    count: 78,
    countbg: "#202020",
  },
  {
    id: 6,
    icon: (
      <img
        src={Ice_Hockey}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Ice Hockey",
    link: "/ice_hockey",
    count: 4,
    countbg: "#202020",
  },
  {
    id: 7,
    icon: (
      <img
        src={Disk}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Moter Sport",
    link: "/moter_sport",
    count: 4,
    countbg: "#202020",
  },
  {
    id: 8,
    icon: (
      <img
        src={Badminton}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Basketball",
    link: "/basketball",
    count: 3,
    countbg: "#202020",
  },
  {
    id: 9,
    icon: (
      <img
        src={TableTannis}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Table Tennis ",
    link: "/table_tennis",
    count: 3,
    countbg: "#202020",
  },
  {
    id: 10,
    icon: (
      <img
        src={Bedminton}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Bedminton",
    link: "/bedminton",
    count: 6,
    countbg: "#202020",
  },
  {
    id: 11,
    icon: (
      <img
        src={Star}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Archery",
    link: "/archery",
    count: 3,
    countbg: "#202020",
  },
  {
    id: 12,
    icon: (
      <img
        src={HorseRacing}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Horse Racing",
    link: "/horse_racing",
    count: 15,
    countbg: "#202020",
  },
  {
    id: 13,
    icon: (
      <img
        src={GrayHound}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Greyhound Racing",
    link: "/greyhound_racing",
    count: 37,
    countbg: "#202020",
  },
  {
    id: 14,
    icon: (
      <img
        src={Kabaddi}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Kabaddi",
    link: "/kabaddi",
    count: 19,
    countbg: "#202020",
  },
  {
    id: 15,
    icon: (
      <img
        src={Kabaddi}
        alt="Clock Icon"
        className="hover:w-[25px]"
        style={{ width: "20px", height: "20px" }}
      />
    ), // Replace with your icon, // Replace with your icon
    title: "Kabaddi",
    link: "/kabaddi",
    count: 75,
    countbg: "#202020",
  },
];
export const tabData = [
  {
    title: "Providers",
    content: [
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Supernowa",
        valueleft: "50%",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        title: "Powergame ",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        title: "Ezugi",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        title: "RtgSlots",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        title: "Royal Gaming",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        title: "Evolution",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        title: "IOI betgame",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        title: "Q tech ",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        title: "Evoplay",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        title: "Spribe",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Hacksaw",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        title: "Ezugi",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        title: "RtgSlots",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        title: "Royal Gaming",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        title: "Evolution",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        title: "IOI betgame",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        title: "Q tech ",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        title: "Evoplay",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
    ],
  },
  {
    title: "Live",
    content: [
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        title: "Q tech ",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        title: "Evoplay",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        title: "Spribe",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Hacksaw",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Supernowa",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        title: "Powergame ",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        title: "Ezugi",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        title: "RtgSlots",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        title: "Royal Gaming",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        title: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        title: "IOI betgame",
      },
    ],
  },
  {
    title: "Virtual",
    content: [
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        title: "Powergame ",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        title: "RtgSlots",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        title: "Royal Gaming",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        title: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        title: "IOI betgame",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Supernowa",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        title: "Powergame ",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        title: "Ezugi",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        title: "RtgSlots",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        title: "Royal Gaming",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        title: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        title: "IOI betgame",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        title: "Q tech ",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        title: "Evoplay",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        title: "Spribe",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Hacksaw",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Supernowa",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        title: "Q tech ",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        title: "Evoplay",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        title: "Spribe",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        title: "Hacksaw",
      },
    ],
  },
];
export const tabCasino = [
  {
    title: "All",
    content: [
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/assets/images/casinoimg/default.jpg",
        valueone: "Monopoly Live",
        valueTwo: "Supernowa",
      },
      {
        id: 2,
        image: "https://cdn.cloudd.site/vking/lobby/1666778594062.webp?v=2",
        valueone: "Classic Speed Blackjack 58 Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/vking/lobby/baccarat_control_squeeze1.webp",
        valueone: "Baccarat Control Squeeze",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/vking/lobby/casino_holdem.webp",
        valueone: "No Commission Baccarat",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image:
          "https://cdn.cloudd.site/vking/lobby/new/no-comission-baccarat.webp?v=11",
        valueone: "Casino Hold'em",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image: "https://cdn.cloudd.site/vking/lobby/20210244592919.webp?v=5",
        valueone: "Lightning Dice",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image: "https://cdn.cloudd.site/vking/lobby/standard%20blackjack.webp",
        valueone: "RNG European Lightning D",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/vking/lobby/rng-european-roulette.webp?v=1",
        valueone: "RNG European Roulette DN",
        valueTwo: "Supernowa",
      },
      {
        id: 8,
        image: "https://cdn.cloudd.site/vking/lobby/blackjack.webp",
        valueone: "RNG Standard Blackjack D",
        valueTwo: "Supernowa",
      },
      {
        id: 9,
        image: "https://cdn.cloudd.site/vking/lobby/ultimate_texas_holdem.webp",
        valueone: "Ultimate Texas Hold em Ultimate Txas Hold'em",
        valueTwo: "Supernowa",
      },
      {
        id: 10,
        image: "https://cdn.cloudd.site/vking/lobby/lightning_baccarat.webp",
        valueone: "Lightning Baccarat",
        valueTwo: "Ezugi",
      },
      {
        id: 11,
        image: "https://cdn.cloudd.site/vking/lobby/peek_baccarat.webp",
        valueone: "Peek Baccarat",
        valueTwo: "Ezugi",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/vking/lobby/baccarat_control_squeeze1.webp",
        valueone: "Baccarat Control Squeeze",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/vking/lobby/casino_holdem.webp",
        valueone: "No Commission Baccarat",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image:
          "https://cdn.cloudd.site/vking/lobby/new/no-comission-baccarat.webp?v=11",
        valueone: "Casino Hold'em",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image: "https://cdn.cloudd.site/vking/lobby/20210244592919.webp?v=5",
        valueone: "Lightning Dice",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image: "https://cdn.cloudd.site/vking/lobby/standard%20blackjack.webp",
        valueone: "RNG European Lightning D",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/vking/lobby/rng-european-roulette.webp?v=1",
        valueone: "RNG European Roulette DN",
        valueTwo: "Supernowa",
      },
      {
        id: 8,
        image: "https://cdn.cloudd.site/vking/lobby/blackjack.webp",
        valueone: "RNG Standard Blackjack D",
        valueTwo: "Supernowa",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/vking/lobby/baccarat_control_squeeze1.webp",
        valueone: "Baccarat Control Squeeze",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/vking/lobby/casino_holdem.webp",
        valueone: "No Commission Baccarat",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image:
          "https://cdn.cloudd.site/vking/lobby/new/no-comission-baccarat.webp?v=11",
        valueone: "Casino Hold'em",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image: "https://cdn.cloudd.site/vking/lobby/20210244592919.webp?v=5",
        valueone: "Lightning Dice",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image: "https://cdn.cloudd.site/vking/lobby/standard%20blackjack.webp",
        valueone: "RNG European Lightning D",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/vking/lobby/rng-european-roulette.webp?v=1",
        valueone: "RNG European Roulette DN",
        valueTwo: "Supernowa",
      },
      {
        id: 8,
        image: "https://cdn.cloudd.site/vking/lobby/blackjack.webp",
        valueone: "RNG Standard Blackjack D",
        valueTwo: "Supernowa",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/vking/lobby/casino_holdem.webp",
        valueone: "No Commission Baccarat",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image:
          "https://cdn.cloudd.site/vking/lobby/new/no-comission-baccarat.webp?v=11",
        valueone: "Casino Hold'em",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image: "https://cdn.cloudd.site/vking/lobby/20210244592919.webp?v=5",
        valueone: "Lightning Dice",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image: "https://cdn.cloudd.site/vking/lobby/standard%20blackjack.webp",
        valueone: "RNG European Lightning D",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/vking/lobby/rng-european-roulette.webp?v=1",
        valueone: "RNG European Roulette DN",
        valueTwo: "Supernowa",
      },
      {
        id: 8,
        image: "https://cdn.cloudd.site/vking/lobby/blackjack.webp",
        valueone: "RNG Standard Blackjack D",
        valueTwo: "Supernowa",
      },
    ],
  },
  {
    title: "New",
    content: [
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Bet on Teen Patti",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Bet on Teen Patti",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Italian Blackjack",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Live Roulette",
        valueTwo: "Evolution",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Italian Blackjack",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
    ],
  },
  {
    title: "Popular",
    content: [
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
    ],
  },
  {
    title: "Live",
    content: [
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
    ],
  },
  {
    title: "Virtual",
    content: [
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 2,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/powergamesdark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 3,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/ezugidark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 4,
        image: "https://cdn.cloudd.site/Vking/b2cdark/betgamesdark.png",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 5,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/royalgamedark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 6,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evolutiondark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 7,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/evoplaydark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 1,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 8,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/spridedark.webp",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 9,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/RTG.webp?v=7",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 10,
        image:
          "https://cdn.cloudd.site/content/casinoimg/orangeprovider/hacksaw-dark.webp?v=1",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
      {
        id: 11,
        image:
          "https://cdn.cloudd.site/content/casinoimg/newproviderdark/supernovadark.webp?v=82",
        valueone: "Monopoly Live",
        valueTwo: "Evolution",
      },
    ],
  },
];
export const dataItems = [
  {
    id: 1,
    game: "Cricket",
    // n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        inplay: true,
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        inplay: true,
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        inplay: false,
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     inplay: false,
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     inplay: false,
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     inplay: false,
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     game: "Cricket",
  //     n_of_game: "1",
  //     team1: "seri lanka",
  //     teams: [
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //       {
  //         team1: "Sri Lanka",
  //         team2: "New Zealand",
  //         match: "Test Matches",
  //         score1: "1.01",
  //         score2: "1.02",
  //         score3: "210",
  //         score4: "240",
  //         score5: "180",
  //         score6: "220",
  //         star: <CiStar />,
  //         fillStar: <FaStar />,
  //       },
  //     ],
  //   },

  // Add more data as needed
];

export const CricketItems = [
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Cricket",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];

export const tennisItem = [
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Tennis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];

export const SoccerItem = [
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Soccer",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];

export const IceHockeyItem = [
  {
    id: 1,
    game: "Ice Hockey",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Ice Hockey",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const MoterSportItem = [
  {
    id: 1,
    game: "Moter Sport",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Moter Sport",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const BedmintontItem = [
  {
    id: 1,
    game: "Bedminton",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Bedminton",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const TableTannistItem = [
  {
    id: 1,
    game: "Table Tannis",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Table Tannis",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const BasketballItem = [
  {
    id: 1,
    game: "Basket Ball",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Basket Ball",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const HorseRacingtItem = [
  {
    id: 1,
    game: "Horse Racing",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Horse Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const ArcheryItem = [
  {
    id: 1,
    game: "Archery",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Archery",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const GrayHoundItem = [
  {
    id: 1,
    game: "Grayhound Racing",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Grayhound Racing",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
export const KabadditItem = [
  {
    id: 1,
    game: "Kabaddi",

    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },
  {
    id: 1,
    game: "Kabaddi",
    n_of_game: "1",
    team1: "seri lanka",
    teams: [
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
      {
        team1: "Sri Lanka",
        team2: "New Zealand",
        match: "Test Matches",
        score1: "1.01",
        score2: "1.02",
        score3: "210",
        score4: "240",
        score5: "180",
        score6: "220",
        star: <CiStar />,
        fillStar: <FaStar />,
      },
    ],
  },

  // Add more data as needed
];
//   export const MoterSportItem  = [
//     {
//         id: 1,
//         game: "Moter Sport",

//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//             star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }

//   // Add more data as needed
//   ];
//   export const MoterSportItem  = [
//     {
//         id: 1,
//         game: "Moter Sport",

//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//             star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }

//   // Add more data as needed
//   ];
//   export const MoterSportItem  = [
//     {
//         id: 1,
//         game: "Moter Sport",

//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//             star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }

//   // Add more data as needed
//   ];
//     export const MoterSportItem  = [
//     {
//         id: 1,
//         game: "Moter Sport",

//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//             star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, ]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//             score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     },  {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }, {
//         id: 1,
//         game: "Moter Sport",
//         n_of_game: '1',
//         team1:'seri lanka',
//         teams: [{
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }, {
//             team1: "Sri Lanka", team2: "New Zealand", match: "Test Matches", score1: "1.01",
//             score2: "1.02",
//             score3: "210",
//             score4: "240",
//             score5: "180",
//            score6: "220",
//            star: <CiStar/>,
//             fillStar:<FaStar/>
//         }]
//     }

//   // Add more data as needed
//   ];

export const socialLinks = [
  {
    icon: <FaInstagram className="font-montserrat font-[600]" />,
    label: "Instagram",
    url: "https://www.instagram.com/khelo_bharat_official/",
  },
  {
    icon: <FaFacebookF className="font-montserrat font-[600]" />,
    label: "Facebook",
    url: "https://www.facebook.com/profile.php?id=61558240769753",
  },
  {
    icon: <BiLogoTelegram className="font-montserrat font-[600]" />,
    label: "Telegram",
    url: "about:blank",
  },
  {
    icon: <RiWhatsappFill className="font-montserrat font-[600]" />,
    label: "Whatsapp",
    url: "https://api.whatsapp.com/send/?phone=918770769264&text&type=phone_number&app_absent=0",
  },
];
export const footerLinks = [
  { label: "About Us", href: "#" },
  { label: "KYC Policy", href: "#" },
  { label: "Terms & Conditions", href: "#" },
  { label: "Responsible Gambling", href: "#" },
  { label: "IPL Rules", href: "#" },
  { label: "Blog", href: "#" },
];
export const options = [
  { label: "All Games", path: "/all-games" },
  { label: "Option A", path: "/option-a" },
  { label: "Option B", path: "/option-b" },
  { label: "Option C", path: "/option-c" },
];
