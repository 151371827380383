export const SPORT = Object.freeze({
    "1" : "Football",
    "2" : "Tennis",
    "4" : "Cricket"
})

export const SPORTS_ICON = Object.freeze({
    "1": "https://hattrick247.com/static/media/Soccer.2b29a53cf065aa58cee68686901535c1.svg",
    "2" : "https://hattrick247.com/static/media/Tennis.eca59ff90f26a3a64f475615e75903f6.svg",
    "4" : "https://res.cloudinary.com/dionqkh8u/image/upload/v1726778156/3115782_drd6qf.png"
})