    // userSlice.js
    import { createSlice } from '@reduxjs/toolkit';

    const initialState = {
    token: null,
    user: null,
    };

    const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
        const { token, user } = action.payload; // Make sure payload structure is correct
        state.token = token;
        state.user = user;
        },
        clearCredentials: (state) => {
        state.token = null;
        state.user = null;
        },
    },
    });

    export const { setCredentials, clearCredentials } = userSlice.actions;
    export default userSlice.reducer;
