export function getFormattedDate (date) {
    const isoDate = new Date(date);

// Get the day, month, and time in the required format
const day = isoDate.getDate();
const month = isoDate.toLocaleString('en-US', { month: 'short' }); // Oct
const hours = isoDate.getHours().toString().padStart(2, '0'); // 24-hour format with leading zero
const minutes = isoDate.getMinutes().toString().padStart(2, '0'); // Add leading zero to minutes if necessary

// Format the result
const formattedDate = `${day} ${month} - ${hours}:${minutes}`;

return formattedDate;
} 