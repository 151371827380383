// src/components/modals/RegisterModal.js

import React, { useState } from 'react';
import { ImCross } from "react-icons/im";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import ForgetModel from "../Modal/Forgetpassword"
import 'react-phone-input-2/lib/style.css';
import { useSignUpMutation } from '../../Redux/ApiSlice';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { setCredentials } from "../../Redux/userSlice";

const RegisterModal = ({ isModalOpen,isloginModelOpen, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [registerUser, { isLoading }] = useSignUpMutation();
  const [isForgetModalOpen, setisForgetModalOpen] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const openForgetPasswordModal = () => {
    setisForgetModalOpen(true);
  };


  // Validation schema for registration
  const registrationValidationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string()
      .min(12, 'Invalid phone number')
      .required('Phone is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  const handleRegistration = async (values, { setSubmitting, setErrors,resetForm }) => {
    const newUser = {
      email: values.email,
      password: values.password,
      mobile: values.phone,
    };

    try {
      const result = await registerUser(newUser).unwrap();
      const { token, user } = result;

      dispatch(setCredentials({ token, user }));
      resetForm(); 
      isloginModelOpen();
      setSubmitting(false);
      closeModal();
    } catch (error) {
      console.error('Registration failed:', error);
      setErrors({ general: error.data?.message || 'Registration failed. Please try again.' });
      setSubmitting(false);
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "modal-background") {
      closeModal();
    }
  };
  const closeForgetModal = () => {
    setisForgetModalOpen(false);
  };

  if (!isModalOpen) return null;

  return (
    <div
      id="modal-background"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="bg-custon-right-side-bg px-6 mx-3 lg:mx-0 lg:mt-0 py-3 rounded-xl h-[549px] overflow-scroll  lg:h-[600px] shadow-lg w-[440px] lg:w-[660px] your-scroll-container-class">
        {/* Close Button */}
        <div className='flex justify-end items-center'>
          <ImCross onClick={closeModal} className='dark:bg-black bg-white rounded-full p-2 text-[30px] text-white cursor-pointer' />
        </div>

        {/* Logo */}
        <div className='flex justify-center items-center'>
          <img
            src="https://res.cloudinary.com/djlpb1ld5/image/upload/v1727980998/WhatsApp_Image_2024-10-03_at_23.37.42_4b7305c4_nfutdo.jpg"
            alt="logo"
            className='h-[50px] w-[200px]'
          />
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4  '>
          {/* Illustration */}
          <div className='my-7 hidden lg:block'>
            <img src='https://cdn.cloudd.site/theme/khelo-bharat_theme/khelo-bharat/assets/images/login-img-new-dark.png' alt='Register' />
            <p className='font-[700] text-[22px] font-montserrat text-center text-custom-yellow'>100% FAST Withdrawal</p>
            <img src='https://cdn.cloudd.site/content/assets/images/login-payment-dark.png?v=1.0.4' alt='Footer' className='h-[20px] w-full' />
          </div>

          {/* Registration Form */}
          <div className='my-2'>
            <Formik
              initialValues={{
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                keepMeSignedIn: false,
              }}
              validationSchema={registrationValidationSchema}
              onSubmit={handleRegistration}
            >
              {({ isSubmitting, setFieldValue, errors }) => (
                <Form className="space-y-3 mt-3">
                  {/* Email Field */}
                  <div className='my-6 relative'>
                    <label htmlFor="email" className="block absolute top-1 text-[12px] left-4 text-custom-yellow">
                      Enter Your Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                      placeholder=""
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm px-3" />
                  </div>

                  {/* Phone Input for Registration */}
                  <div className="my-6 flex relative justify-center">
                    <label htmlFor="phone" className="block absolute z-10 top-1 text-[12px] left-4 text-custom-yellow">
                      Enter the Country Code
                    </label>
                    <PhoneInput
                      country={'us'}
                      onChange={value => setFieldValue('phone', value)}
                      enableSearch={true}
                      onlyCountries={['us', 'gb', 'in', 'ca', 'au', 'fr', 'de','pk']}
                      inputStyle={{
                        backgroundColor: '#000000',
                        border: '1px solid #374151',
                        borderRadius: '1.5rem',
                        width:'100%',
                        color: '#D1D5DB',
                        paddingLeft: '50px',
                        paddingTop: '1.5rem',
                        paddingBottom: '1.5rem',
                        fontSize: '14px',
                      }}
                      containerClass="w-full"
                    />
                    
                  </div>
                  <ErrorMessage name="phone" component="div" className="text-red-500 text-sm px-3" />

                  {/* Password Field */}
                  <div className="my-6 relative">
                    <label htmlFor="password" className="block absolute top-1 text-[12px] left-4 text-custom-yellow">
                      Create a Password
                    </label>
                    <Field
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      id="password"
                      className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                      placeholder=""
                    />
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm px-3" />
                    <span onClick={togglePasswordVisibility} className='absolute right-4 top-6 cursor-pointer'>
                      {showPassword ? <BsEyeFill className='text-custom-yellow' /> : <BsEyeSlashFill className='text-custom-yellow' />}
                    </span>
                  </div>

                  {/* Confirm Password for Registration */}
                  <div className="my-6 relative">
                    <label htmlFor="confirmPassword" className="block absolute top-1 text-[12px] left-4 text-custom-yellow">
                      Confirm Password
                    </label>
                    <Field
                      type={showPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      id="confirmPassword"
                      className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                      placeholder=""
                    />
                    <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-sm px-3" />
                  </div>

                  {/* Keep Me Signed In & Forgot Password */}
                  <div className='flex justify-between'>
                    {/* <label className='text-custom-yellow flex items-center'>
                      <Field type="checkbox" name="keepMeSignedIn" className='mr-2' />
                      Keep me signed in
                    </label> */}
                    <span onClick={openForgetPasswordModal} className='text-gray-400 cursor-pointer hover:underline'>Forgot password?</span>
                  </div>

                  {/* Submit Button */}
                  <div className='flex justify-center'>
                    <button
                      type="submit"
                      className="w-[230px] bg-custom-yellow text-black py-3 px-4 rounded-3xl"
                      disabled={isSubmitting || isLoading}
                    >
                      {isLoading ? 'Registering...' : 'Register'}
                    </button>
                  </div>

                  {/* General Error Message */}
                  {errors.general && <div className="text-red-500 text-center">{errors.general}</div>}
                </Form>
              )}
            </Formik>

            {/* Switch to Login */}
            <div className='text-center'>
              <p className='mt-4 text-[14px] text-gray-400'>
                Already have an account?{' '}
                <span
                  className='text-custom-yellow cursor-pointer'
                  onClick={() => {
                    closeModal();
                   
                  }}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ForgetModel isModalOpen={isForgetModalOpen}
       closeModal={closeForgetModal} 
        />
    </div>
  );
};

export default RegisterModal;
