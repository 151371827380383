export const matchesEndpoints = (builder) => ({
  getAllSports: builder.query({
    query: () => ({
      url: `/matches/getAllSports`, // Adjust to your actual endpoint
      method: "GET",
    }),
  }),
  getAllMatches: builder.query({
    query: (id) => ({
      url: `/matches/getMatches/${id}`, // Adjust to your actual endpoint
      method: "GET",
    }),
    // Handle side effects on successful login
  }),
});
