import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// const environment = import.meta.env;

// Log the environment variables to check their values
// console.log('Environment Variables:', environment);
const baseUrl = "https://api.hattricks.games";
// const baseUrl = "http://localhost:4000";

// Check if the baseUrl is properly set
if (!baseUrl) {
  throw new Error("VITE_API_BASE_URL is not defined in the environment variables.");
}

export const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  // credentials: "include",
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    return headers;
  },
});
