import { setCredentials } from './userSlice'; // Adjust the import path as necessary

export const userEndpoints = (builder) => ({
    signUp: builder.mutation({
        query: (credentials) => ({
            url: `/users/user_registration`, // Adjust to your actual endpoint
            method: 'POST',
            body: credentials,
        }),
    }),
    signIn: builder.mutation({
        query: (credentials) => ({
            url: `/users/user_login`, // Adjust to your actual endpoint
            method: 'POST',
            body: credentials,
        }),
        // Handle side effects on successful login
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                const { data } = await queryFulfilled; // Await the fulfilled query

                // Assuming your response structure contains the token and user
                const token = data.token; // Adjust based on your API response
                const user = data.user;   // Adjust based on your API response

                // Store token and user in Redux
                dispatch(setCredentials({ token, user }));

                // Store token in localStorage
                localStorage.setItem('token', token);
            } catch (error) {
                console.error('Sign in failed:', error);
                // Optionally handle error or dispatch an action
            }
        },
    }),
    forgotPassword: builder.mutation({
        query: (credentials) => {
          return {
            url: `/users/user_reset_password_token`,
            method: "POST",
            body: credentials,
          };
        },
      }),
});
