import { configureStore } from '@reduxjs/toolkit';
import { api } from './ApiSlice'; // Change this to import `api`
import userReducer from './userSlice';

export const store = configureStore({
  reducer: {
    user:userReducer,
    [api.reducerPath]: api.reducer, // Use `api` instead of `apiSlice`
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
