import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { userEndpoints } from "./userEndpoint";
import { matchesEndpoints } from "./matchesEndpoint";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    ...userEndpoints(builder),
    ...matchesEndpoints(builder),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useGetAllMatchesQuery,
  useGetAllSportsQuery,
  // useResetPasswordMutation,
  useForgotPasswordMutation,
  // useGetUserDataQuery,
} = api;
